import { Injectable, NgZone } from '@angular/core';
import { navigation } from 'src/app/navigation/navigation';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { CcmcApiService } from './ccmc-api.service';
import { Router } from '@angular/router';
import { SpinnerService } from './spinner.service';
import { ActiveLosService } from './active-los.service';
import { CustomErrorHandlerService } from './custom-error-handler.service';
import { AssetService } from './asset.service';
import { filter, catchError, takeUntil } from 'rxjs/operators';
import { ErrorDialogComponent } from '../components/error-dialog/error-dialog.component';
import { SuccessDialogComponent } from '../components/success-dialog/success-dialog.component';
import { environment } from 'src/environments/environment';
import { CaptureDataService } from './capture-data.service';
import { AmplifyService } from './amplify.service';
import { GlobalSearchService } from './global-search.service';
import { TargetResponseDialogComponent } from '../components/target-response-dialog/target-response-dialog.component';
import { UpdateMiserCredentialsComponent } from '../components/update-miser-credentials/update-miser-credentials.component';
import { CCMCCollateralSearchDialogComponent } from '../components/collateral-search-dialog/collateral-search-dialog.component';
import { Subject } from 'rxjs';
const { Buffer } = require('buffer');

@Injectable({
  providedIn: 'root'
})
export class MiserService {
  dynamicNav: any = navigation;
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };
  unsubscribe: Subject < any > = new Subject();
  originalCashBoxNumber: any;
  constructor(
    private dialog: MatDialog,
    private ccmcApiService: CcmcApiService,
    public router: Router,
    private spinnerService: SpinnerService,
    private activeLosService: ActiveLosService,
    private zone: NgZone,
    private http: HttpClient,
    private customErrorHandlerService: CustomErrorHandlerService,
    private assetService: AssetService,
    private amplifyService: AmplifyService,
    private captureDataService: CaptureDataService,
    private globalSearchService: GlobalSearchService
  ) {
    this.httpOptions.headers.append('Access-Control-Allow-Origin', '*');
    this.httpOptions.headers.append(
      'Access-Control-Allow-Methods',
      'POST, GET, OPTIONS, PUT'
    );
    this.httpOptions.headers.append('Accept', 'application/json');
  }

  /**
   * Send
   * @description Sends the loan to the ibs communicator
   * @memberof MiserService
   */
  send() {
    let userName = this.amplifyService.username;
    const exportObject = {
      content: {
        mapping: this.ccmcApiService.mapping,
        configurations: this.ccmcApiService.configurations,
        transactions: this.ccmcApiService.transactions,
        cognitoID: this.amplifyService.getCognitoId()
      },
      user: userName,
      assetID: this.assetService.getSelectedAssetId()
    };
    this.spinnerService.setShowSpinner(true);
    // this.collateralSearch();
    this.export(exportObject).subscribe(async result => {
      const parsedResult = JSON.parse(JSON.stringify(result));
      console.log(parsedResult);
      if (parsedResult.error) {
        const errorMessage = {
          statusMessage: "Ground Control failed to receive a response back from the communicator",
          statusFlag: false
        };
        const dialogRef2 = this.zone.run(() => {
          this.dialog.open(TargetResponseDialogComponent, {
            data: errorMessage
          });
        });
        await this.ccmcApiService.logExportResponse({
          assetID: this.assetService.getSelectedAssetId(),
          loanNumber: this.getLoanNumber(),
          statusMessage: parsedResult.error,
          statusFlag: false
        });
      }
      if (parsedResult.statusFlag === false) {
        if (parsedResult.content && parsedResult.content.mapping) {
          // Retrieve Validation From existing mapping object
          let existingMappingCopy = JSON.parse(
            JSON.stringify(this.ccmcApiService.mapping)
          );
          for (let mappingItem of parsedResult.content.mapping) {
            let existingMappingIndex = existingMappingCopy.findIndex(
              (obj: any) => obj.fieldID === mappingItem.fieldID
            );
            if (
              existingMappingIndex > -1 &&
              existingMappingCopy[existingMappingIndex].validation
            ) {
              mappingItem.validation =
                existingMappingCopy[existingMappingIndex].validation;
            }
          }
          this.ccmcApiService.mapping = parsedResult.content.mapping;
        }
        if (parsedResult.content && parsedResult.content.transactions) {
          this.ccmcApiService.transactions =
            parsedResult.content.transactions;
        }
        this.globalSearchService.initData();
        const errorMessage = {
          statusMessage: parsedResult.statusMessage,
          statusFlag: false
        };
        const dialogRef = this.dialog.open(
          TargetResponseDialogComponent,
          {
            data: errorMessage
          }
        );
        await this.ccmcApiService.logExportResponse({
          assetID: this.assetService.getSelectedAssetId(),
          loanNumber: this.getLoanNumber(),
          statusMessage: parsedResult.statusMessage,
          statusFlag: parsedResult.statusFlag
        });
      }
      if (parsedResult.statusFlag === true) {
        const loanNumber = this.getLoanNumber();
        const resultMessage = {
          statusMessage: parsedResult.statusMessage,
          loanNumber: loanNumber,
          statusFlag: true
        };
        const dialogRef = this.dialog.open(
          TargetResponseDialogComponent,
          {
            data: resultMessage
          }
        );
        await this.ccmcApiService.logExportResponse({
          assetID: this.assetService.getSelectedAssetId(),
          loanNumber: loanNumber,
          statusMessage: parsedResult.statusMessage,
          statusFlag: parsedResult.statusFlag
        });
                   
        if (parsedResult.statusFlag === true) {
          this.activeLosService.activeLosService.loanBooked(
            parsedResult,
            JSON.parse(JSON.stringify(this.ccmcApiService.configurations))
          );
          this.ccmcApiService.mapping = undefined;
          this.ccmcApiService.coreSettings = undefined;
          this.ccmcApiService.conditions = undefined;
          this.ccmcApiService.mapping = undefined;
          this.ccmcApiService.supportValues = undefined;
          this.ccmcApiService.search = undefined;
          this.ccmcApiService.transactions = undefined;
          this.ccmcApiService.configurations = undefined;
          if (this.dynamicNav[1].children[1]) {
            this.dynamicNav[1].children[1].children = [];
          }
          this.dynamicNav[1].children[0].badge.title = undefined;
          if (this.activeLosService.activeLos === 'encompass' || this.activeLosService.activeLos === 'bytepro') {
            this.router.navigate(['loan-lookup']);
          } else {
            this.router.navigate(['file-selector']);
          }

          this.ccmcApiService.originalCashBoxNumber
          .pipe(takeUntil(this.unsubscribe))
            .subscribe(cashBoxNumber => {
              if (cashBoxNumber) {
                console.log(cashBoxNumber);
                this.originalCashBoxNumber = cashBoxNumber;
              }
            });
          
          if(!this.ccmcApiService.configurations.saveCredentials) {
            delete this.ccmcApiService.configurations.tellerNumber
            delete this.ccmcApiService.configurations.pin
            delete this.ccmcApiService.configurations.stationName
            this.ccmcApiService.configurations.miserCashBoxNumber = this.originalCashBoxNumber;
          }
        }
      }
    });
  }

  /**
   * Customer Search
   * @description performs the customers search in the ibs communicator
   * @param {*} borrower
   * @param {*} callback
   * @memberof MiserService
   */
  customerSearch(borrower: any, callback: any) {
    let userName = this.amplifyService.username;
    const request = {
      content: borrower,
      assetID: this.assetService.getSelectedAssetId(),
      user: userName
    };
    console.log('Request: \n', JSON.stringify(request));
    request.content.cognitoID = this.amplifyService.getCognitoId();
    this.searchCustomer(request).subscribe(res => {
      console.log('Customer search response: ', res);
      const parsedResult = JSON.parse(JSON.stringify(res));
      console.log(parsedResult);
      if (parsedResult.error) {
        const errorMessage = {
          message: JSON.stringify(parsedResult.error.message),
          title: 'Miser Result'
        };

        const dialogRef = this.dialog.open(ErrorDialogComponent, {
          data: errorMessage
        });
      }
      if (parsedResult.statusFlag === true) {
        this.spinnerService.setShowSpinner(false);
        const payload = parsedResult.content;
        if (payload.length > 0) {
          const searchResult = payload;
          console.log(searchResult);
          callback(searchResult);
        } else {
          const noResult = [
            {
              firstName: 'No Result',
              lastName: '',
              taxID: '',
              CIF: '',
              address: ''
            }
          ];
          const searchResult = noResult;
          console.log(searchResult);
          callback(searchResult);
        }
      } else if (parsedResult.statusFlag === false) {
        const errorMessage = {
          message: parsedResult.statusMessage,
          title: 'Search Error'
        };

        const dialogRef = this.dialog.open(ErrorDialogComponent, {
          data: errorMessage
        });
        dialogRef.afterClosed().subscribe((res: any) => {
          if (
            parsedResult.statusMessage.includes('ERR805 PIN OR OFFSET ERROR') ||
            parsedResult.statusMessage.includes('ERR726 EMP NOT ON FILE') ||
            parsedResult.statusMessage.includes('WRN000 DIFF MACH OR POS')
          ) {
            const miserCredDialogRef = this.dialog.open(
              UpdateMiserCredentialsComponent,
              {
                panelClass: 'suffix-search__panel-dialog'
              }
            );
          }
        });
      }
    });
  }

  /**
   * Assign Borrowers
   * @description set the field value to the customer name
   * @param {*} party
   * @memberof MiserService
   */
  assignBorrowers(party: any) {
    for (let i = 0; i < party.length; i++) {
      const CIFFields = party[i].CIFFields.split(',');
      CIFFields.forEach((cf: any) => {
        const cifIndex = this.ccmcApiService.mapping.findIndex(
          (f: any) => f.fieldID === cf.trim()
        );
        this.ccmcApiService.mapping[cifIndex].fieldValue = party[i].CIF;
      });
    }
  }

  /**
   * Search Customer
   * @description calls the search customer api
   * @param {*} params
   * @returns
   * @memberof MiserService
   */
  searchCustomer(params: any) {
    
    console.log(params);
    return this.http
      .post(`${Buffer.from(environment.environmentURL, "base64").toString()}/fis/miser/search-customer`, params, this.httpOptions)
      .pipe(catchError(this.customErrorHandlerService.handleError));
  }

  /**
   * Export
   * @description calls the export api
   * @param {*} params
   * @returns
   * @memberof MiserService
   */
  export(params: any) {
    return this.http
      .post(`${Buffer.from(environment.environmentURL, "base64").toString()}/fis/miser/export-messages`, params, this.httpOptions)
      .pipe(catchError(this.customErrorHandlerService.handleError));
  }
  /**
   * auto Customer Search
   * @description performs the auto customers search in the horizon communicator
   * @param {*} borrower
   * @param {*} callback
   * @memberof IBSService
   */
  autoCustomerSearch(borrower: any, callback: any) {
    let userName = this.amplifyService.username;
    const request = {
      content: borrower,
      assetID: this.assetService.getSelectedAssetId(),
      user: userName
    };
    console.log('Request: \n', JSON.stringify(request));
    request.content.cognitoID = this.amplifyService.getCognitoId();
    this.searchCustomer(request).subscribe(res => {
      console.log('Customer search response: ', res);
      const parsedResult = JSON.parse(JSON.stringify(res));
      console.log(parsedResult);
      if (parsedResult.error) {
        const errorMessage = {
          message: JSON.stringify(parsedResult.error.message),
          title: 'Miser Result'
        };

        const dialogRef = this.dialog.open(ErrorDialogComponent, {
          data: errorMessage
        });
      }
      if (parsedResult.statusFlag === true) {
        this.spinnerService.setShowSpinner(false);
        const payload = parsedResult.content;
        if (payload.length > 0) {
          const searchResult = payload;
          console.log(searchResult);
          callback(searchResult);
        } else {
          const noResult = [
            {
              firstName: 'No Result',
              lastName: '',
              taxID: '',
              CIF: '',
              address: ''
            }
          ];
          const searchResult = noResult;
          console.log(searchResult);
          callback(searchResult);
        }
      } else if (parsedResult.statusFlag === false) {
        this.spinnerService.setShowSpinner(false);
        if (
          parsedResult.statusMessage
            .toLowerCase()
            .includes('no customer record found')
        ) {
          const noResult = [
            {
              firstName: 'No Result',
              lastName: '',
              taxID: '',
              CIF: '',
              address: ''
            }
          ];
          const searchResult = noResult;
          console.log(searchResult);
          callback(searchResult);
        } else {
          const errorMessage = {
            message: parsedResult.statusMessage,
            title: 'Search Error'
          };

          const dialogRef = this.dialog.open(ErrorDialogComponent, {
            data: errorMessage
          });
          dialogRef.afterClosed().subscribe((res: any) => {
            if (
              parsedResult.statusMessage.includes(
                'ERR805 PIN OR OFFSET ERROR'
              ) ||
              parsedResult.statusMessage.includes('ERR726 EMP NOT ON FILE') ||
              parsedResult.statusMessage.includes('WRN000 DIFF MACH OR POS')
            ) {
              const miserCredDialogRef = this.dialog.open(
                UpdateMiserCredentialsComponent,
                {
                  panelClass: 'suffix-search__panel-dialog'
                }
              );
              miserCredDialogRef.afterClosed().subscribe(res => {
                return callback(parsedResult);
              });
            } else {
              return callback(parsedResult);
            }
          });
        }
      }
    });
  }

  getLoanNumber() {
    console.log('get Loan number');
    let loanFieldIndex = this.ccmcApiService.mapping.findIndex(
      (obj: any) => obj.fieldID === 'NL1_01'
    );
    if (loanFieldIndex > -1) {
      // Return loan number
      console.log(
        'loan number',
        this.ccmcApiService.mapping[loanFieldIndex].fieldValue
      );
      return this.ccmcApiService.mapping[loanFieldIndex].fieldValue;
    }
  }

  // collateralSearch() {
  //   // retrieve the index where the transactionid is 'coll-01'
  //   console.log(this.ccmcApiService.transactions);
  //   const CIFTransactionIndex = this.ccmcApiService.transactions.findIndex(
  //     (obj: any) => obj.transactionID.toLowerCase() === 'xcm'
  //   );
  //   console.log(CIFTransactionIndex);

  //   // determines if the display of the selected transaction is 'Y'
  //   const containsCollateral =
  //     this.ccmcApiService.transactions[CIFTransactionIndex].display === true;

  //   if (containsCollateral) {
  //     this.spinnerService.setShowSpinner(false);
  //     const dialogRef = this.dialog.open(CCMCCollateralSearchDialogComponent, {
  //       panelClass: 'collateral-search__dialog'
  //     });

  //     dialogRef.afterClosed().subscribe(send => {
  //       if (send === true) {
  //         this.spinnerService.setShowSpinner(true);
  //         this.export().subscribe(async result => {
  //           const parsedResult = JSON.parse(JSON.stringify(result));
  //           console.log(parsedResult);
  //           if (parsedResult.error) {
  //             const errorMessage = {
  //               statusMessage: "Ground Control failed to receive a response back from the communicator",
  //               statusFlag: false
  //             };
  //             const dialogRef2 = this.zone.run(() => {
  //               this.dialog.open(TargetResponseDialogComponent, {
  //                 data: errorMessage
  //               });
  //             });
  //             await this.ccmcApiService.logExportResponse({
  //               assetID: this.assetService.getSelectedAssetId(),
  //               loanNumber: this.getLoanNumber(),
  //               statusMessage: parsedResult.error,
  //               statusFlag: false
  //             });
  //           }
  //           if (parsedResult.statusFlag === false) {
  //             if (parsedResult.content && parsedResult.content.mapping) {
  //               // Retrieve Validation From existing mapping object
  //               let existingMappingCopy = JSON.parse(
  //                 JSON.stringify(this.ccmcApiService.mapping)
  //               );
  //               for (let mappingItem of parsedResult.content.mapping) {
  //                 let existingMappingIndex = existingMappingCopy.findIndex(
  //                   (obj: any) => obj.fieldID === mappingItem.fieldID
  //                 );
  //                 if (
  //                   existingMappingIndex > -1 &&
  //                   existingMappingCopy[existingMappingIndex].validation
  //                 ) {
  //                   mappingItem.validation =
  //                     existingMappingCopy[existingMappingIndex].validation;
  //                 }
  //               }
  //               this.ccmcApiService.mapping = parsedResult.content.mapping;
  //             }
  //             if (parsedResult.content && parsedResult.content.transactions) {
  //               this.ccmcApiService.transactions =
  //                 parsedResult.content.transactions;
  //             }
  //             this.globalSearchService.initData();
  //             const errorMessage = {
  //               statusMessage: parsedResult.statusMessage,
  //               statusFlag: false
  //             };
  //             const dialogRef = this.dialog.open(
  //               TargetResponseDialogComponent,
  //               {
  //                 data: errorMessage
  //               }
  //             );
  //             await this.ccmcApiService.logExportResponse({
  //               assetID: this.assetService.getSelectedAssetId(),
  //               loanNumber: this.getLoanNumber(),
  //               statusMessage: parsedResult.statusMessage,
  //               statusFlag: parsedResult.statusFlag
  //             });
  //           }
  //           if (parsedResult.statusFlag === true) {
  //             const loanNumber = this.getLoanNumber();
  //             const resultMessage = {
  //               statusMessage: parsedResult.statusMessage,
  //               loanNumber: loanNumber,
  //               statusFlag: true
  //             };
  //             const dialogRef = this.dialog.open(
  //               TargetResponseDialogComponent,
  //               {
  //                 data: resultMessage
  //               }
  //             );
  //             await this.ccmcApiService.logExportResponse({
  //               assetID: this.assetService.getSelectedAssetId(),
  //               loanNumber: loanNumber,
  //               statusMessage: parsedResult.statusMessage,
  //               statusFlag: parsedResult.statusFlag
  //             });
                         
  //             if (parsedResult.statusFlag === true) {
  //               this.activeLosService.activeLosService.loanBooked(
  //                 parsedResult,
  //                 JSON.parse(JSON.stringify(this.ccmcApiService.configurations))
  //               );
  //               this.ccmcApiService.mapping = undefined;
  //               this.ccmcApiService.coreSettings = undefined;
  //               this.ccmcApiService.conditions = undefined;
  //               this.ccmcApiService.mapping = undefined;
  //               this.ccmcApiService.supportValues = undefined;
  //               this.ccmcApiService.search = undefined;
  //               this.ccmcApiService.transactions = undefined;
  //               this.ccmcApiService.configurations = undefined;
  //               if (this.dynamicNav[1].children[1]) {
  //                 this.dynamicNav[1].children[1].children = [];
  //               }
  //               this.dynamicNav[1].children[0].badge.title = undefined;
  //               if (this.activeLosService.activeLos === 'encompass' || this.activeLosService.activeLos === 'bytepro') {
  //                 this.router.navigate(['loan-lookup']);
  //               } else {
  //                 this.router.navigate(['file-selector']);
  //               }
  //             }
  //           }
  //         });
  //       }
  //     });
  //   } else {
  //     this.spinnerService.setShowSpinner(true);
  //     this.export().subscribe(async result => {
  //       const parsedResult = JSON.parse(JSON.stringify(result));
  //       console.log(parsedResult);
  //       if (parsedResult.error) {
  //         const errorMessage = {
  //           statusMessage: "Ground Control failed to receive a response back from the communicator",
  //           statusFlag: false
  //         };
  //         const dialogRef2 = this.zone.run(() => {
  //           this.dialog.open(TargetResponseDialogComponent, {
  //             data: errorMessage
  //           });
  //         });
  //         await this.ccmcApiService.logExportResponse({
  //           assetID: this.assetService.getSelectedAssetId(),
  //           loanNumber: this.getLoanNumber(),
  //           statusMessage: parsedResult.error,
  //           statusFlag: false
  //         });
  //       }
  //       if (parsedResult.statusFlag === false) {
  //         const errorMessage = {
  //           statusMessage: parsedResult.statusMessage,
  //           statusFlag: false
  //         };
  //         const dialogRef = this.dialog.open(TargetResponseDialogComponent, {
  //           data: errorMessage
  //         });
  //         await this.ccmcApiService.logExportResponse({
  //           assetID: this.assetService.getSelectedAssetId(),
  //           loanNumber: this.getLoanNumber(),
  //           statusMessage: parsedResult.statusMessage,
  //           statusFlag: parsedResult.statusFlag
  //         });
  //       }
  //       if (parsedResult.statusFlag === true) {
  //         let loanNumber;
  //         if (parsedResult.loanNumber) {
  //           loanNumber = parsedResult.loanNumber;
  //         } else {
  //           loanNumber = this.getLoanNumber();
  //         }
  //         const resultMessage = {
  //           statusMessage: parsedResult.statusMessage,
  //           loanNumber: loanNumber,
  //           statusFlag: true
  //         };
  //         this.captureDataService.uploadS3File(this.ccmcApiService.mapping);
  //         this.activeLosService.activeLosService.loanBooked(
  //           parsedResult,
  //           JSON.parse(JSON.stringify(this.ccmcApiService.configurations))
  //         );

  //         await this.ccmcApiService.logExportResponse({
  //           assetID: this.assetService.getSelectedAssetId(),
  //           loanNumber: loanNumber,
  //           statusMessage: parsedResult.statusMessage,
  //           statusFlag: parsedResult.statusFlag
  //         });
          
  //         this.ccmcApiService.mapping = undefined;
  //         this.ccmcApiService.coreSettings = undefined;
  //         this.ccmcApiService.conditions = undefined;
  //         this.ccmcApiService.mapping = undefined;
  //         this.ccmcApiService.supportValues = undefined;
  //         this.ccmcApiService.search = undefined;
  //         this.ccmcApiService.transactions = undefined;
  //         this.ccmcApiService.configurations = undefined;
  //         if (this.dynamicNav[1].children[1]) {
  //           this.dynamicNav[1].children[1].children = [];
  //         }
  //         this.dynamicNav[1].children[0].badge.title = undefined;
  //         const dialogRef = this.dialog.open(TargetResponseDialogComponent, {
  //           data: resultMessage
  //         });
  //         dialogRef.afterClosed().subscribe(result => {
  //           if (this.activeLosService.activeLos === 'encompass' || this.activeLosService.activeLos === 'bytepro') {
  //             this.router.navigate(['loan-lookup']);
  //           } else {
  //             this.router.navigate(['file-selector']);
  //           }
  //         });
  //       }
  //     });
  //   }
  // }

  searchCollateral(params: any) {
    
    console.log(params);
    return this.http
      .post(
        `${Buffer.from(environment.environmentURL, "base64").toString()}/fis/miser/search-collateral`,
        params,
        this.httpOptions
      )
      .pipe(catchError(this.customErrorHandlerService.handleError));
  }

}
