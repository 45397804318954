<mat-toolbar>
  <div
    fxLayout="row"
    fxLayoutAlign="center center"
    fxLayoutAlign.gt-xs="space-between center"
    fxFlex
  >
    <div fxLayout="row" fxLayoutAlign="start center" fxHide fxShow.gt-xs>
      <!-- <a mat-button href="#">Documentation</a>
      <span>&bull;</span>
      <a mat-button href="#">Changelog</a> -->
      <div style="bottom: 0%; right: 1%; position: absolute">
       <a href="https://kinective-io.atlassian.net/wiki/spaces/GCtrl/overview" target="_blank" style="color: white;cursor:pointer"> Release v{{ version }} </a>
      </div>
    </div>
  </div>
</mat-toolbar>
